import { jotaColors } from '@JOTAJornalismo/jota-design-system/dist/components/Card/cardTypes'
import { VerticalName, VerticalType } from '../interfaces/PRO.interfaces'
import { AuthState } from '@App/api/types/auth'

export const getVertical = (vertical: VerticalType) => {
    return `JOTA PRO ${vertical}`
}

export const getColorVertical = (vertical: VerticalType): jotaColors => {
    switch (vertical.toLowerCase()) {
        case VerticalName.PODER.toLowerCase():
            return 'jotaBlue' as jotaColors
        case VerticalName.TRIBUTOS.toLowerCase():
            return 'jotaGreen' as jotaColors
        case VerticalName.SAUDE.toLowerCase():
            return 'jotaPurple' as jotaColors
        case VerticalName.ENERGIA.toLowerCase():
            return 'jotaOrange' as jotaColors
        case VerticalName.TRABALHISTA.toLowerCase():
            return 'jotaGray' as jotaColors
        default:
            return 'jotaOrange' as jotaColors
    }
}

const blockVisibilityAccordingToUserVertical = (
    vertical: string,
    auth: AuthState
) => {
    const userBO: any = auth.user?.getUserBO()
    if (userBO?.profile) {
        switch (vertical) {
            case VerticalName.PODER.toLowerCase():
            case VerticalName.ALERTA.toLowerCase():
                return !userBO.profile.has_poder

            case VerticalName.TRIBUTOS.toLowerCase():
                return !userBO.profile.has_tributos

            case VerticalName.SAUDE.toLowerCase():
                return !userBO.profile.has_saude

            case VerticalName.TRABALHISTA.toLowerCase():
                return !userBO.profile.has_trabalhista

            default:
                return true
        }
    } else {
        return true
    }
}

export const isUserPro = (vertical: VerticalType, auth: any): boolean => {
    if (auth.user?.getUserBO()?.profile) {
        return !blockVisibilityAccordingToUserVertical(vertical, auth)
    }
    return false
}

export const isContentPro = (vertical: VerticalType, auth: any): boolean => {
    return !isUserPro(vertical, auth)
}

export const getEventByType = (type: VerticalType) => {
    const eventMap: { [key in VerticalType]?: string } = {
        [VerticalName.PODER]: 'click-form-rd-poder',
        [VerticalName.TRIBUTOS]: 'click-form-rd-tributos',
        [VerticalName.SAUDE]: 'click-form-rd-saude',
        [VerticalName.TRABALHISTA]: 'click-form-rd-trabalhista'
    }

    return eventMap[type] ?? 'FORM_RD_CLICADO'
}

export const validateFields = (
    email: string,
    companyEmployees: string,
    personalPhone: string
): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const telefoneRegex = /^\+55 \(\d{2}\) \d{4,5}-\d{4}$/

    const isEmailValid = emailRegex.test(email)
    const isPhoneValid = telefoneRegex.test(personalPhone)
    const isCompanyEmployeesValid =
        companyEmployees !== 'Selecione' && companyEmployees !== ''

    return isEmailValid && isPhoneValid && isCompanyEmployeesValid
}
